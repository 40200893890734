@use "../../config/variables.scss" as vars;
.pricing-card1 {
  width: 325px;
  height: 530px;
  background-color: #ffffff;
  border-radius: 41px;
  padding: 30px;
  border: solid 1px #bcbcbc;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width:576px) {
    width: 290px;
    height: 470px;
  }
}
.pricing-Box1 {
  padding-top: 79px;
}
.bg-image {
  background-image: url(../../assets/images/pricing-bg.png);
  background-color: #f6f6f6;
  background-repeat: no-repeat;
  width: 100%;
}
.pricing-componentCover {
  padding: 178px 0px 100px 0px;
}
.pricing-text1 {
  color: rgb(33, 196, 33);
  text-align: center;
  font-family: vars.$Poppins-ExtraBold;
  font-size: 40px;
  @media (max-width:768px) {
    font-size: 35px;
  }
  @media (max-width:576px) {
    font-size: 26px;
    padding: 0px 20px;
  }
}
.pricing-text2 {
  color: grey;
  text-align: center;
  font-family: vars.$Poppins-Medium;
  font-size: 23px;
  @media (max-width:768px) {
    font-size: 18px;
  }
  @media (max-width:576px) {
    font-size: 15px;
    padding: 0px 20px;
  }
}
.pricing-card2 {
  width: 320px;
  height: 620px;
  border-radius: 46.13px;
  background-color: #ffbc49;
  padding: 40px 30px 30px 30px;
  box-shadow: 0px 25px 40px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width:576px) {
    width: 280px;
    height: 500px;
  }
}
.pricing-box2-head1 {
  font-family: vars.$Poppins-ExtraBold;
  font-size: 30.02px;
  text-align: center;
  color: #878f86;
  padding-bottom: 20px;
  border-bottom: solid 1px #d3d3d3;
  @media (max-width: 576px) {
    font-size: 25px;
  }
}
.pricing-box2-head2-subhead {
  color: #878f86;
  font-size: 50.33px;
  font-family: vars.$Poppins-ExtraBold;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 40px;
  }
}
.pricing-box2-head2-subhead1 {
  font-size: 24.39px;
  color: #878f86;
  font-family: vars.$Poppins-ExtraBold;
  @media (max-width: 576px) {
    font-size: 20px;
  }
}
.pricing-card2-body {
  color: #222222;
  font-family: vars.$Poppins-Medium;
  font-size: 16px;
  line-height: 50px;
  @media (max-width:1200px) {
    font-size: 13px;
  }
}
.pricing-button {
  background-color: #878f86;
  color: #ffffff !important;
  font-family: vars.$Poppins-Bold !important;
  font-size: 19.65px !important;
  border-radius: 16px !important;
  height: 69px !important;
  border: none;
  @media (max-width:576px) {
    border-radius: 9.12px!important;
  }
}
.pricing-box2 {
  border: none;
  background: linear-gradient(286.1deg, #0ea000 12.77%, #38cd2a 84.05%);
  z-index: 10;
  border-radius: 34.17px;
  width: 350px;
  height: 165px;
  margin-left: -45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width:576px) {
    margin-left: -40px;
    border-radius: 19px;
    width: 300px;
    padding-top:10px;
  }
}
.pricing-whiteColor {
  color: #ffffff;
  padding-bottom: 0px !important;
}
.pricing-buttonColor {
  background: linear-gradient(286.1deg, #0ea000 12.77%, #38cd2a 84.05%);
}
.pricing-miniCardCover{
  padding: 100px 230px;
  @media (max-width:1300px) {
    padding: 100px 180px;
  }
  @media (max-width:1100px) {
    padding: 100px 130px;
  }
  @media (max-width:600px) {
    padding: 50px 40px;
  }
}
.pricing-minicard-image{
  width: 100%;
  height: 180px;
}
.pricing-miniCard-head1{
  text-align: center;
  color: #34B628;
  font-size: 18px;
  font-family: vars.$Poppins-Bold;
  padding-bottom: 14px;
  @media (max-width:1130px) {
    font-size: 16px;
  }
}
.pricing-miniCard-head2{
  font-size: 16px;
  font-family: vars.$Poppins-Medium;
  color: vars.$fullblack;
  padding-top: 14px;
  @media (max-width:1250px) {
    font-size: 14px;
  }
}
// .pricing-Box1Cover{
//   @media (max-width:1400px) {
//     display: flex;
//     justify-content: space-between;
//     flex-direction: column;
//   }
// }
.picing-colPadding{
@media (max-width:576px) {
  padding: 0 !important;
}
}
.pricing-row1{
  @media (max-width:576px) {
    margin: 0 !important;
  }
}