@use "../../../config//variables.scss" as vars;

.comment-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  background-color: rgb(239, 239, 239);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.comment-user {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: rgb(223, 223, 223);
  border-radius: 100%;
  font-size: 15px;
}

.comment-text {
  font-size: 14px;
  color: #000;
}
.comment-text2 {
  font-size: 14px;
  color: #000;
}
