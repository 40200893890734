@use "../../config/variables.scss" as vars;

.ExcelImport-box1 {
    display: flex;
    align-items: center;
}
.ExcelImport-box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border: 1px solid #f2f2f2;
    width: 50%;
    border-radius: 8px;
    font-family: vars.$Poppins-ExtraLight;
}

.ExcelImport-box3 {
    font-family: vars.$Poppins-Medium;
}

.ExcelImport-txt1 {
    font-family: vars.$Poppins-Medium;
    font-size: 18px;
    color: #3f3f3f;
}
.ExcelImport-txt2{
    color: vars.$primary
}