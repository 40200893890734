//colors
$primary: #ff9800;
$primary2: #fcfaf6;
$green: #18a762;
$dark: #25363f;
$backround: #fafafa;
$grey5: #d9d9d9;
$lightgrey: #f5f5f5;
$dpyello: #ffbc49;
$dgrey: #bcbcbc;
$grey: #d3d3d3;
$balck: #222222;
$dasbd: #f4f6f8;
$Green: #34b628;
$GreyGreen: #878f86;
$fullblack: #000000;

//font
$Poppins-Black: "Poppins-Black";
$Poppins-Bold: "Poppins-Bold";
$Poppins-ExtraBold: "Poppins-ExtraBold";
$Poppins-ExtraLight: "Poppins-ExtraLight";
$Poppins-Light: "Poppins-Light";
$Poppins-Medium: "Poppins-Medium";
$Poppins-Regular: "Poppins-Regular";
