@use "../../config/variables.scss" as vars;
.Consolidate-news{
    color: vars.$fullblack;
    font-family: vars.$Poppins-Bold;
    font-size: 30px;
    text-align: center;
    margin: 30px 0px 10px 0px;
}
.Consolidate-news_text{
    font-size: 18px;
    font-family: vars.$Poppins-Medium;
}