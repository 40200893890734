@use "../../config/variables.scss" as vars;
.Services-BoxMain {
  width: 100%;
  height: 100vh;
  background-image: url(../../assets/images/Groupimg.png);
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: 576px) {
    background-image: url(../../assets/images/mobileimg.png);
    background-size: contain;
    height: 50vh;
    background-size: 100% 100%;
  }
}
.Services-Box1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 576px) {
    display: none;
  }
}
.Services-BoxCover {
  padding-left: 180px;
  height: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
    justify-content: center;
  }
}
.Services-Box1Mobile {
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 576px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: vars.$lightgrey;
  }
}

.Services-Text1 {
  font-size: 13px;
  color: #878f86;
  @media (max-width: 576px) {
    text-align: center;
    padding: 30px 10px 10px 10px;
    font-size: 15px;
  }
}
.Services-Text2 {
  font-family: vars.$Poppins-ExtraBold;
  color: vars.$fullblack;
  font-size: 35px;
  @media (max-width: 576px) {
    text-align: center;
    font-size: 24px;
  }
}
.Services-btnDiv {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}
.Services-btn {
  width: 280px;
  height: 40px;
  background-color: #0ea000;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-family: vars.$Poppins-Medium;
}
.Services-Box2 {
  width: 100%;
  padding: 100px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1300px) {
    padding: 100px 150px;
  }
  @media screen and (max-width: 700px) {
    padding: 100px 100px;
  }
  @media screen and (max-width: 576px) {
    padding: 80px 50px;
  }
}
.first-content {
  font-family: vars.$Poppins-Bold;
  text-align: center;
  color: vars.$Green;
  font-size: 18px;
  padding-bottom: 10px;
}
.Services-cardImg {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  @media (max-width: 768px) {
    height: 200px;
  }
  @media (max-width: 576px) {
    height: 150px;
  }
}
.second-content {
  font-size: 14px;
  color: vars.$fullblack;
  font-family: vars.$Poppins-Medium;
  padding-top: 15px;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Services-imgDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding-bottom: 20px;
}
.Services-Text3 {
  position: absolute;
  font-family: vars.$Poppins-Medium;
  font-size: 14px;
  color: vars.$fullblack;
  top: 8px;
}
.Services-Text4 {
  font-size: 30px;
  color: #0ea000;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  font-family: vars.$Poppins-ExtraBold;
  @media (max-width: 768px) {
    font-size: 25px;
  }
  @media (max-width: 576px) {
    font-size: 19px;
  }
}
.Services-Text5 {
  font-size: 20px;
}
.managment-imgBox {
  width: 80%;
  height: 80%;
}
.Services-Box3 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 30px 30px 30px;
  @media (max-width:576px) {
    padding: 0px;
  }
}
.service-card3 {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
}
.service-card3-head1 {
  color: rgb(33, 196, 33);
  text-align: center;

  font-size: 18px;
}
.service-card-image {
  width: 100%;
  height: 180px;
}
.Services-Box4 {
  width: 100%;
  background-color: aliceblue;
}
.AccountEasily-img {
  @media (max-width: 576px) {
    height: 50px;
    width: 385px;
  }
  @media (max-width: 420px) {
    width: 310px;
    height: 60px;
  }
}
.AccountEasily-cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.AccountEasily-cardImgDiv {
  @media (min-width: 576px) {
    height: 260px;
  }
}
.AccountEasily-coverDiv {
  padding: 100px 300px;
  background-color: vars.$lightgrey;
  @media (max-width: 1300px) {
    padding: 100px 250px;
  }
  @media (max-width: 1200px) {
    padding: 100px 200px;
  }
  @media (max-width: 1100px) {
    padding: 100px 150px;
  }
  @media (max-width: 768px) {
    padding: 100px 100px;
  }
  @media (max-width: 576px) {
    padding: 50px 50px;
  }
}
.AccountEasily-TextCoverDiv {
  padding: 20px 30px 20px 0px;
  @media (max-width: 992px) {
    padding: 20px 0px 20px 0px;
  }
}
.AccountEasily-TextCoverDiv1 {
  padding: 20px 0px 20px 30px;
  @media (max-width: 992px) {
    padding: 20px 0px 20px 0px;
  }
}
.AccountEasily-cardTaitil {
  font-family: vars.$Poppins-Bold;
  font-size: 16px;
  color: vars.$Green;
}
.AccountEasily-cardDescription {
  font-family: vars.$Poppins-Medium;
  color: vars.$fullblack;
  font-size: 14px;
  padding-top: 20px;
}
.AccountEasily-Text3 {
  position: absolute;
  font-family: vars.$Poppins-Bold;
  font-size: 15px;
  top: 10px;
  color: vars.$fullblack;
  @media (max-width: 576px) {
    font-size: 13px;
  }
  @media (max-width: 420px) {
    font-size: 12px;
  }
}
