@use "../../config/variables.scss" as vars;
.websiteHeader {
  height: 73px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  display: flex;
  &.scrolled {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(78, 78, 78, 0.1);
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    padding: 10px;
  }
}

.websiteHeader-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
}

.websiteHeader-txt1 {
  display: flex;
  align-items: center;
  height: 100%;
  font-family: vars.$Poppins-Medium;
  font-size: 16px;
  color: #3d3d3d;
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: vars.$green;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}

.websiteHeader-btn2 {
  width: 80%;
  margin-top: 3px;
  font-family: vars.$Poppins-Medium;
  font-size: 14px;
  border: 1px solid vars.$green;
  background-color: transparent;
  color: vars.$green;
  padding: 6px;
  border-radius: 7px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: none;
  }
}
.websiteHeader-logo {
  display: flex;
  align-items: flex-end;
  justify-content: left;
}

.websiteHeader-btn3 {
  display: none;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    display: flex;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(22, 96, 223);
    border: 0px;
  }
}
.websiteHeader-logo {
  width: 130px;
}
.LanguageSwitcherd-div1{
  color: #000000;
  font-family: "Poppins-Regular";
  font-size: 16px;
  display: flex;
  height: 100%;
  align-items: center;
}