.contact-box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  height: 400px;
}
.contact-box2 {
  display: flex;
}
.contact-box3 {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.contactBox-tex1 {
  font-size: 40px;
  font-weight: 900;
  color: #34b628;
}
.contactBox-btn {
  width: 100%;
  height: 50px;
}
