@use "../../config/variables.scss" as vars;

.profile-tab-container {
  justify-content: center;
}
.profile-tags {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .profile-tab-title-text {
    margin-left: 6px;
  }
}
.profile-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;
  .profile-tab-title-text {
    margin-left: 6px;
    font-family: vars.$Poppins-Medium;
  }
}

// subscription-----------------
.subscription-Txt2 {
  font-family: vars.$Poppins-Medium;
  font-size: 20px;
}
.security-Txt1 {
  font-family: vars.$Poppins-Bold;
  font-size: 18px;
}

// ----------- Profile
.profile-picture-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile-picture {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-edit {
  position: absolute;
  bottom: -12px;
  background-color: vars.$primary;
  border-radius: 100px;
  width: 35px;
  height: 35px;
  padding: 5px;
  cursor: pointer;
}

.profile-img {
  width: 90%;
  border-radius: 100%;
  cursor: pointer;
  background-color: #fff;
  padding: 5px;
  border: 1px solid rgb(217, 217, 217);
}

.profile-text-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-txt1 {
  font-family: vars.$Poppins-Medium;
  font-size: 20px;
  display: flex;
  flex-direction: row;
}

.profile-txt2 {
  font-family: vars.$Poppins-Medium;
}
