.language-scroll::-webkit-scrollbar {
    width: 8px;
  }
  
  .language-scroll::-webkit-scrollbar-track {
    background: #fff;
  }
  
  .language-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(240, 240, 240);
    outline: 1px solid rgb(240, 240, 240);
  }
  
  .language-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(202, 202, 202); 
  }
  