// @use "../../config/variables.scss" as vars;
// body {
//     margin: 0;
//     padding: 0;
// }

// .main-body {
//     display: flex;
//     flex-direction: column;
// }

// .main-container {
//     display: flex;
//     flex-direction: column;
// //   background-color: rgb(183, 157, 157) !important;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
// //   min-height: 100vh;
//   min-height: calc(100vh - 90px);

//   @media screen and (max-width: 1252px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// }

// .main-top {
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     @media screen and (max-width: 1252px) {
//         display: flex;
//         flex-direction: column;
//       }
// }

// .left-container {
// //   background-color: rgb(23, 38, 76) !important;
//   width: 50%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-height: 100vh;
//   min-width: 500px;

//   @media screen and (max-width: 674px) {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// }

// .left-row {
//     display: flex;
//     // flex-direction:column;
//     align-items: center;
//     justify-content: end;
//     // background-color: #704949;
//     @media screen and (max-width: 674px) {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         justify-content: center;
//       }
//   }

//   .select-country-heading {
//     font-size: 30px;
//     font-weight: 600;
//     color: rgb(5, 1, 1);
//   }

// .card-container {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

// .right-container {
// //   background-color: rgb(23, 134, 175) !important;
//   width: 50%;
//   // width: 100vw;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   min-width: 500px;
//     min-height: 460px;
// //   height: 100vh;
// //   margin-top: 100px;
// }

// .country-text {
//   // color: red;
//   align-items: center;
//   justify-content: center;
// }

// .country-card {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   width: 120px;
//   height: 120px;
//   border-radius: 10px;
//   transition: transform 0.3s ease-in-out
// }

// .country-card:hover {
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     transform: scale(1.1);
//     color: whitesmoke;
//   }

// .country-image {
//   width: 60px;
//   height: 50px;
//   margin-top: 30px;
//   object-fit: cover;
//   border-radius: 50% !important;
// }

// .circular-image {
//   border-radius: 50%;
// }
// ////////////////////////////////////////////////
// ///
// .tax-form-header {
//   font-size: 40px !important;
//   font-weight: 600;
// }

// .tax-card {
//   // background-color: green;
// //   width: 1000px;
//     min-width: 600px;
//     min-height: 460px;
//   border-radius: 10px;
// //   height: 70vh;
// @media screen and (max-width: 674px) {
//     max-width: 500px;
//   }
// }

// .card-steps {
//   margin-top: 50px;
//   margin-bottom: 50px;
// }

// .results-container {
// //   width: 1000px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // background-color: red !important;
// }

// .list-item {
//   display: flex;
//   justify-content: space-between;
//   // color: red;
//   width: 100%;
// }

// .income-tax-results {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 20px !important;
// }

// .item-title {
//   font-size: 16px;
// }

// .item-value {
//   font-size: 16px;
//   font-weight: 600;
// }

// .results-card {
//   width: 80vw;
// //   flex: 1;
//   background-color: rgb(249, 244, 244);
// }

// .chart-container {
//   display: flex;
//   flex-direction: column;
//   width: 80vw;
//   // background-color: rgb(198, 42, 42);
//   justify-content: space-between;
//   align-items: stretch;
//   margin-top: 50px;
// }

// .left {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   flex: 1; /* this makes each child take up all available width */
//   margin-right: 10px;
// }

// .card-left {
//   width: 100%;
// }

// .right {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   flex: 1; /* this makes each child take up all available width */
//   margin-right: 10px;
// }

// //css loader
// .loader {
//     // position: absolute;
//     top: calc(50% - 32px);
//     left: calc(50% - 32px);
//     width: 64px;
//     height: 64px;
//     border-radius: 50%;
//     perspective: 800px;
//     margin-top: 30px;
//   }

//   .inner {
//     position: absolute;
//     box-sizing: border-box;
//     width: 100%;
//     height: 100%;
//     border-radius: 50%;
//   }

//   .inner.one {
//     left: 0%;
//     top: 0%;
//     animation: rotate-one 1s linear infinite;
//     border-bottom: 3px solid #19e281;
//   }

//   .inner.two {
//     right: 0%;
//     top: 0%;
//     animation: rotate-two 1s linear infinite;
//     border-right: 3px solid #e29f0f;
//   }

//   .inner.three {
//     right: 0%;
//     bottom: 0%;
//     animation: rotate-three 1s linear infinite;
//     border-top: 3px solid #000000;
//   }

//   @keyframes rotate-one {
//     0% {
//       transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
//     }
//     100% {
//       transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
//     }
//   }

//   @keyframes rotate-two {
//     0% {
//       transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
//     }
//     100% {
//       transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
//     }
//   }

//   @keyframes rotate-three {
//     0% {
//       transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
//     }
//     100% {
//       transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
//     }
//   }

@use "../../config/variables.scss" as vars;

body {
  margin: 0;
  padding: 0;
}
.main-body {
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 90px);
  @media screen and (max-width: 1252px) {
    align-items: stretch;
  }
}
.main-top {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1222px) {
    flex-direction: column;
  }
}

.left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 500px;

  @media screen and (max-width: 1225px) {
    width: 100%;
    min-width: 0;
    margin-left: 100px;
  }
  @media screen and (max-width: 770px) {
    width: 100%;
    min-width: 0;
    margin-left: 0px;
  }
}

@media screen and (max-width: 1223px) {
  .main-top {
    display: flex;
    flex-direction: column-reverse;
  }

  .right-container {
    margin-top: 200px;
  }
}

@media screen and (max-width: 720px) {
  .tax-card {
    min-width: 100px;
    min-height: 100px;
    border-radius: 10px;
  }

  .ant-select,
  .ant-input {
    width: 90%;
  }
}

@media screen and (max-width: 576px) {
  .left-row {
    margin-left: -150px;
  }
}

.left-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 674px) {
    flex-direction: column;
  }
}

.select-country-heading {
  font-size: 30px;
  font-weight: 600;
  color: rgb(5, 1, 1);
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  min-height: 460px;
  margin-bottom: 50px;

  @media screen and (max-width: 1252px) {
    width: 100%;
    min-width: 0;
  }
}

.country-text {
  align-items: center;
  justify-content: center;
}

.country-card {
  color: burlywood;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
    color: whitesmoke;
  }
}

.country-image {
  width: 60px;
  height: 50px;
  margin-top: 30px;
  object-fit: cover;
  border-radius: 50% !important;
}

.circular-image {
  border-radius: 50%;
}

.tax-form-header {
  font-size: 40px !important;
  font-weight: 600;
  padding-top: 100px;
}

.tax-card {
  min-width: 600px;
  min-height: 460px;
  border-radius: 10px;
  background-color: rgb(252, 250, 246) !important;

  @media screen and (max-width: 674px) {
    min-width: 525px;
    margin: 10px;
  }
  @media screen and (max-width: 543px) {
    min-width: 0px;
    margin: 10px;
  }
}

.radio-group {
  margin-left: 25px;
}

.card-steps {
  margin-top: 50px;
  margin-bottom: 50px;
}

.results-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.income-tax-results {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
}

.item-title {
  font-size: 16px;
}

.item-value {
  font-size: 16px;
  font-weight: 600;
}

.results-card {
  width: 80vw;
  background-color: rgb(249, 244, 244);
}

.chart-container {
  display: flex;
  flex-direction: column;
  width: 75vw;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 50px;
}

.left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
}

.card-left {
  width: 100%;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-right: 10px;
}

.loader {
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
  margin-top: 30px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #19e281;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #e29f0f;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid vars.$fullblack;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
