@use "../../../config//variables.scss" as vars;

.userList {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.userItem {
  background-color: green;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.project-item {
  height: 150px;
  margin-bottom: 10px;
}

.cards {
  background-color: lavender;
  color: #fff;
}

.button-style {
  border-radius: 20px;
  border: 1px solid orange;
  background-color: white;
  color: orange;
  transition: background-color 0.3s;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.button-style:hover {
  background-color: orange;
  color: #fff;
}

.txt1 {
  color: #000;
  font-family: vars.$Poppins-Medium;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}
