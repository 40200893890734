@use "../../../config/variables.scss" as vars;
.viewpopover-Box2 {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
  &:hover {
    border-left: 1px solid red;
    background-color: #ededed;
  }
}
.viewpopover-Box1 {
  width: 150px;
}
.viewpopover-Txt1 {
  font-family: vars.$Poppins-Medium;
  font-size: 12px;
  color: #1a1a1a;
  padding-left: 8px;
}
.viewCardHead {
  display: flex;
  justify-content: center;
  font-family: vars.$Poppins-Bold;
  font-size: 24px;
}
.product-View-Img1 {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid rgb(183, 182, 182);
  padding: 2px;
}
.product-View-Box2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

// product add form ----------------->>>>>>>>>..
.ProductAdd-Box1 {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  padding: 15px;
  height: 320px;
}
.ProductAdd-Box2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.ProductAdd-Img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.table-actionBoxItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: rgb(235, 235, 235);
    border-radius: 4px;
    color: #18a762;
    cursor: pointer;
  }
}
