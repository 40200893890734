@use "../../config/variables.scss" as vars;
.Terms-mainTaitil{
    font-family: vars.$Poppins-Black;
    color: vars.$fullblack;
    font-size: 36px;
    margin-top: 50px;
}
.Terms-desc{
    font-family: vars.$Poppins-Medium;
    color: vars.$GreyGreen;
    font-size: 16px;
    margin: 20px 0px 20px 0px;
}
.Terms_taitil{
    font-family: vars.$Poppins-Bold;
    color: vars.$fullblack;
    font-size: 28px;
    margin-top: 10px;
}
.Terms-desc1{
    color: vars.$Green;
}

