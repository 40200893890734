@use "../../config/variables.scss" as vars;
.support-box1 {
  display: flex;
  justify-content: end;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
}
.support-image1 {
  object-fit: contain;
  background-repeat: no-repeat;
  width: 400px;
  @media screen and (max-width: 576px) {
    width: 100%;
  }
}
.support-head1 {
  padding: 15px 0px 5px;
  font-family: vars.$Poppins-Regular;
  font-size: 14px;
  color: grey;
}
.support-head2 {
  font-family: vars.$Poppins-Bold;
  padding: 5px 0px 5px;
  font-size: 30px;
  color: #18a762;
}
.support-head3 {
  line-height: 20px;
  font-family: vars.$Poppins-Medium;
  font-size: 13px;
  color: grey;
}
.support-button {
  margin-top: 15px;
  font-family: vars.$Poppins-Medium;
  text-align: center;
  color: #ffffff;
  font-size: 10px;
}

.secondsec-head1 {
  text-align: center;
  font-family: vars.$Poppins-Medium;
  padding: 10px 0px 5px;
  font-size: 30px;
  @media screen and (max-width: 576px) {
    padding: 10px;
  }
}
.secondsec-head2 {
  text-align: center;
  font-family: vars.$Poppins-Light;
  font-size: 13px;
  @media screen and (max-width: 576px) {
    padding: 10px;
  }
}
.supportcards-head1 {
  padding: 2px 0px 2px;
  font-family: vars.$Poppins-Bold;
  cursor: pointer;
}
.supportcards-head2 {
  cursor: pointer;
  padding: 2px 0px 2px;
  font-size: 13px;
  font-family: vars.$Poppins-Regular;
  color: grey;
}
.supportcards-parent {
  padding: 30px 0px 30px 0px;
  @media screen and (max-width: 576px) {
    padding: 10px 10px 10px 10px;
  }
}
.supportcards {
  padding: 10px 5px 10px 5px;
  cursor: pointer;
  @media screen and (max-width: 576px) {
    padding: 10px 20px 10px 20px;
  }
}

.homescreen-whatsapp-box2 {
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 80px;
  align-items: center;
  bottom: 50px;
  position: fixed;
  z-index: 900;
  background: transparent;
  right: 1px;
  @media screen and (max-width: 576px) {
  }
}
