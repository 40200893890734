@use "../../../config/variables.scss" as vars;
.bank-Card {
  .cardHead {
    color: orange;
    border-radius: 10px !important;
  }
  .ant-btn.ant-btn-sm {
    padding: 0px;
  }
  .NormalText {
    font-family: vars.$Poppins-Light;
  }
}
.text-1 {
  font-family: vars.$Poppins-Bold;
  font-size: 20px;
  color: rgb(38, 38, 38) 5, 63, 63;
}
.bank-details-label-text {
  font-family: vars.$Poppins-Medium;
  font-size: 16px;
}
.Reconcile-Txt1 {
  font-family: vars.$Poppins-Bold;
  font-size: 20px;
  color: rgb(38, 38, 38) 5, 63, 63;
  padding-bottom: 24px;
}

.view-bank-transfer-set {
  display: flex;
  justify-content: space-between;
  h6 {
    font-family: vars.$Poppins-Medium;
    font-size: 18px;
    padding-top: 5px;
    color: grey;
  }
}
.view-bank-transfer-title {
  font-family: vars.$Poppins-Medium;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}
.bank-details-tab-title {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin-left: 6px;
    font-family: vars.$Poppins-Medium;
    font-size: 16px;
  }
}
.salesReciept-Box1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.salesReciept-Txt1 {
  font-family: vars.$Poppins-Bold;
  font-size: 20px;
  color: #404040;
  letter-spacing: 1px;
  padding: 10px;
}
.tab-item-Txt {
  font-family: vars.$Poppins-Medium;
  font-size: 16px;
}

.addBank-Card{
  background: transparent;
  border: 2PX solid rgb(223, 223, 223);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family:vars.$Poppins-Medium;
  font-size: 16px;
}