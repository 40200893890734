@use "../config/variables.scss" as vars;

.RetailExpress {
  background-color: vars.$primary2;
  height: 100vh;
  overflow: hidden;
}

.RetailExpress-Header {
  height: 8vh;
  background-color: #fff;
  border-bottom: 1px solid rgb(222, 222, 222);
  padding: 0px 10px;
}

.RetailExpress-HeaderBox2 {
  height: 8vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.RetailExpress-Headertxt1 {
  font-family: vars.$Poppins-Medium;
  font-size: 20px;
}

.RetailExpress-box1 {
  height: 100vh;
}

.RetailExpress-box2 {
  height: 92vh;
  overflow: scroll;
}

.RetailExpress-header2{
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  padding: 0px 10px;
}

.RetailExpress-box3 {
  height: 100%;
  background-color: #fff;
  border-left: 1px solid rgb(222, 222, 222);
  display: flex;
  flex-direction: column;
}

.RetailExpress-ProductItem {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  border: 1px solid rgb(236, 236, 236);
  overflow: hidden;
}

.RetailExpress-ProductItemImg{
  width: 100%;
  height: 100px;
  object-fit: contain;
  background-color: rgb(246, 246, 246);
}

.RetailExpress-ProductItemBox{
  padding: 10px;
  border-top: 1px solid rgb(222, 222, 222);
}

.RetailExpress-txt1{
  font-family: vars.$Poppins-Bold;
  font-size: 20px;
}

.RetailExpress-box4{
  height: 65vh;
  padding: 15px;
  overflow: scroll;
}

.RetailExpress-ListItem{
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgb(222, 222, 222);
  padding-bottom: 10px;
  margin-top: 10px;
  &:last-child{
    border-bottom: 0px;
  }
}

.RetailExpress-ListItemtxt{
  margin-right: 10px;
  font-family: vars.$Poppins-Bold;
  font-size: 14px;
}

.RetailExpress-ListItemtxt1{
  font-family: vars.$Poppins-Medium;
  font-size: 15px;
}
.RetailExpress-ListItemtxt2{
  font-size: 13px;
  color: gray;
}

.RetailExpress-table{
  width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
}

.RetailExpress-txt5{
  font-family: vars.$Poppins-Regular;
  color: grey;
  font-size: 14px;
}

.RetailExpress-txt6{
  text-align: right;
  font-family: vars.$Poppins-Medium;
}
.RetailExpress-ProductItemtxt1{
  font-family: vars.$Poppins-Regular;
  font-size: 14px;
}
.RetailExpress-ProductItemtxt2{
  font-family: vars.$Poppins-Bold;
  font-size: 14px;
}
.RetailExpress-ProductItemtxt3{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: red;
}
.RetailExpress-box7{
  border-top: 1px solid rgb(222, 222, 222);
  padding: 14px;
}

.holder_container {
  border: solid #d3d3d3 1px;
  padding: 10px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.holder_text{
  font-size: 14px;
  font-family: vars.$Poppins-Bold;
  display: flex;
  justify-content: space-between;
}

.holder_text2{
  font-size: 13px;
  font-family: vars.$Poppins-Regular;
}