.dashboard-container {
  padding: 20px;
}

.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
}

.card-count {
  font-size: 30px;
  margin-top: 10px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.data-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.data-table tr:hover {
  background-color: #ddd;
}
