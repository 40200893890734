body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.width100 {
  width: 100%;
}

:where(.css-dev-only-do-not-override-135apkz).ant-input-number-affix-wrapper .ant-input-number-handler-wrap {
  z-index: 2;
  display: none;
}

@font-face {
  font-family: 'Poppins-Black';
  src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}


@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}